import { Box } from '@mui/material';
import {
  GridCellParams,
  GridColDef,
  GRID_AGGREGATION_FUNCTIONS,
  GRID_ROW_GROUPING_SINGLE_GROUPING_FIELD,
  useKeepGroupedColumnsHidden,
} from '@mui/x-data-grid-premium';
import { endOfMonth, startOfMonth } from 'date-fns';
import React, { useEffect } from 'react';
import { isNotNull } from 'shared/utils/utils';
import AppDataGrid from 'src/components/AppDataGrid/AppDataGrid';
import PhaseKind from 'src/components/cells/PhaseKind/PhaseKind';
import ExportToolbar from 'src/components/ExportToolbar/ExportToolbar';
import AddProjectButton from 'src/components/ProjectList/AddProjectButton';
import { useDataGrid } from 'src/hooks/useDataGrid';
import { reduceHoursByUser } from 'src/models/Hour';
import { Phase } from 'src/models/Phase';
import { PhaseHoursByDepartmentRow } from 'src/models/PhaseUserHoursByDepartement';
import { useFindHoursQuery } from 'src/services/hours.service';
import { useLazyGetPhaseQuery } from 'src/services/phase.service';
import { useFindProjectsQuery } from 'src/services/project.service';
import { useLazyGetUserQuery } from 'src/services/user.service';
import {
  currencyColDef,
  hourColDef,
  projectReferenceColDef,
  projectTitleColDef,
} from 'src/utils/columnUtils';
import { v4 as uuidv4 } from 'uuid';

interface Props {
  current: Date;
}

const PlanningHourList = ({ current }: Props) => {
  const { apiRef, isGroupExpandedByDefault, initialStateFilter } =
    useDataGrid('planningHour');

  const initialState = useKeepGroupedColumnsHidden({
    apiRef,
    initialState: {
      ...initialStateFilter,
      rowGrouping: {
        model: ['projectId', 'phaseId'],
      },
      aggregation: {
        model: {
          hours: 'sum',
          cost: 'sum',
          thermalHours: 'sum',
          thermalCost: 'sum',
          structuralHours: 'sum',
          structuralCost: 'sum',
          economyHours: 'sum',
          economyCost: 'sum',
          electricityHours: 'sum',
          electricityCost: 'sum',
        },
      },
      pinnedColumns: {
        left: [
          'projectId',
          'actions',
          'projectReference',
          'projectTitle',
          GRID_ROW_GROUPING_SINGLE_GROUPING_FIELD,
          'kind',
          'phaseId',
          'userName',
        ],
      },
    },
  });

  const { data: projects } = useFindProjectsQuery();

  const { data: hoursData } = useFindHoursQuery({
    startDate: startOfMonth(current).toISOString(),
    endDate: endOfMonth(current).toISOString(),
  });

  const [getPhaseQuery] = useLazyGetPhaseQuery();
  const [getUserQuery] = useLazyGetUserQuery();

  const [rows, setRows] = React.useState<PhaseHoursByDepartmentRow[]>([]);

  const getProject = (id: string) => {
    return projects?.find((project) => project.id === id);
  };

  useEffect(() => {
    (() =>
      Promise.all(
        reduceHoursByUser(hoursData ?? []).map(async (hour) => {
          const { data: phase } = await getPhaseQuery(hour.phaseId, true);
          const { data: user } = await getUserQuery(hour.userId, true);
          if (!phase || !user) return null;
          return {
            id: uuidv4(),
            phaseId: hour.phaseId,
            kind: phase.kind,
            projectId: phase.project.id,
            projectReference: phase.project.reference,
            projectTitle: phase.project.title,
            userId: hour.userId,
            userName: `${user.firstName} ${user.lastName}`,
            hours: hour.hours,
            cost: hour.cost,
            thermalHours: hour.department === 'THERMAL' ? hour.hours : 0,
            thermalCost: hour.department === 'THERMAL' ? hour.cost : 0,
            structuralHours: hour.department === 'STRUCTURAL' ? hour.hours : 0,
            structuralCost: hour.department === 'STRUCTURAL' ? hour.cost : 0,
            economyHours: hour.department === 'ECONOMY' ? hour.hours : 0,
            economyCost: hour.department === 'ECONOMY' ? hour.cost : 0,
            electricityHours:
              hour.department === 'ELECTRICITY' ? hour.hours : 0,
            electricityCost: hour.department === 'ELECTRICITY' ? hour.cost : 0,
          };
        })
      ).then((rows) => setRows(rows.filter(isNotNull))))();
  }, [hoursData]); // eslint-disable-line react-hooks/exhaustive-deps

  const columns: GridColDef[] = [
    {
      ...projectReferenceColDef(true),
      renderCell: (params: GridCellParams<Phase>) =>
        params.rowNode.type === 'group'
          ? getProject(params.rowNode.groupingKey as string)?.reference
          : '',
    },
    {
      ...projectTitleColDef(true),
      renderCell: (params: GridCellParams<Phase>) =>
        params.rowNode.type === 'group'
          ? getProject(params.rowNode.groupingKey as string)?.title
          : '',
    },
    {
      field: 'projectId',
      headerName: '',
      type: 'string',
      filterable: false,
      width: 100,
      sortable: false,
      resizable: false,
      aggregable: false,
      disableColumnMenu: true,
      renderCell: () => <></>,
    },
    {
      field: 'phaseId',
      headerName: '',
      filterable: false,
      width: 100,
      sortable: false,
      resizable: false,
      aggregable: false,
      disableColumnMenu: true,
      cellClassName: 'expand-action',
      type: 'expandAction',
      renderCell: (params) =>
        params.rowNode.type === 'group' &&
        params.rowNode.groupingField === 'phaseId' ? (
          <PhaseKind phaseId={params.rowNode.groupingKey as string} />
        ) : (
          ''
        ),
    },
    {
      field: 'userName',
      headerName: 'Collaborateur',
      type: 'string',
      filterable: false,
      width: 150,
      sortable: false,
      resizable: false,
      aggregable: false,
      disableColumnMenu: true,
      renderCell: (params) => <>{params.value}</>,
    },
    hourColDef('hours', 'Global'),
    { ...currencyColDef('cost', 'Coût'), width: 80 },
    hourColDef('thermalHours', 'Thermique'),
    { ...currencyColDef('thermalCost', 'Coût'), width: 80 },
    hourColDef('structuralHours', 'Structure'),
    { ...currencyColDef('structuralCost', 'Coût'), width: 80 },
    hourColDef('economyHours', 'Economie'),
    { ...currencyColDef('economyCost', 'Coût'), width: 80 },
    hourColDef('electricityHours', 'Electricité'),
    { ...currencyColDef('electricityCost', 'Coût'), width: 80 },
  ];

  const toolbar = () => (
    <>
      <ExportToolbar
        additionalButtons={[<AddProjectButton key={'add_project_button'} />]}
        showQuickFilter={true}
      />
    </>
  );

  return (
    <>
      <Box height="calc(100vh - 250px)">
        <AppDataGrid
          apiRef={apiRef}
          initialState={initialState}
          rows={rows}
          columns={columns}
          groupingColDef={{
            hideDescendantCount: true,
            headerName: 'Phase',
            headerAlign: 'right',
            width: 120,
            disableColumnMenu: true,
          }}
          isCellEditable={() => false}
          isGroupExpandedByDefault={isGroupExpandedByDefault}
          slots={{ toolbar }}
          aggregationFunctions={{
            ...GRID_AGGREGATION_FUNCTIONS,
          }}
        />
      </Box>
    </>
  );
};

export default PlanningHourList;
